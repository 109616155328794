import React, { useEffect, useState } from 'react';
import { Button, Input, notification, Select, Spin } from 'antd'; // Import Spin for the spinner
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { hasErrorFactory } from '../../../../../../../utils/hasError';
import { IReport } from '../../../../../../../interfaces/patient';
import "./reportForm.scss";
import api from "../../../../../../../api";
import { selectOptionFilter } from '../../../../../../../hooks/selectOptionFilter';

type Props = {
  patientId?: string;
  report?: IReport;
  onSubmit: (report: IReport) => void;
  onCancel: () => void;
  submitText?: string;
};

const defaultSubmitText = 'Modifier le rapport';
const emptyReport = {
  fileName: '',
  title: '',
  type: '',
  date: null,
  patientId: '',
  bucketName: 'rapport',
  description : ''
};

const reportSchema = Yup.object().shape({
  title: Yup.mixed().required('Le titre est requis'),
  type: Yup.mixed().required('Le type est requis'),
  description: Yup.string() // Add description field (optional)

});

const ReportForm = ({
  patientId,
  submitText = defaultSubmitText,
  report = emptyReport,
  onSubmit,
  onCancel
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission

  const {
    handleChange,
    handleBlur,
    setFieldTouched,
    values,
    setValues,
    handleSubmit,
    isValid,
    errors,
    touched,
    resetForm,
    setFieldValue
  } = useFormik<IReport>({
    validationSchema: reportSchema,
    initialValues: report,
    onSubmit: async (form) => {
      setIsSubmitting(true); // Start submission
      try {
        await updateReport(values);
      } finally {
        setIsSubmitting(false); // End submission
      }
    }
  });

  const updateReport = async (values: IReport) => {
    try {
      const formData = new FormData();
      formData.append('type', values.type);
      formData.append('title', values.title);
      formData.append('date', values.date);
      formData.append('patientId', values.patientId);
      formData.append('bucketName', values.bucketName);
      formData.append('description', values.description); // Add description field

      await api.put(`report/${report._id}`, formData);
      
      notification.success({
        message: 'Rapport Modifié',
        description: `Le rapport "${values.title}" a été modifié avec succès.`,
        duration: 4,
      });

      onSubmit({ ...values });
      resetForm();
      onCancel();
    } catch (error) {
      notification.error({
        message: 'Échec de la Modification',
        description: 'Une erreur est survenue lors de la modification du rapport. Veuillez réessayer.',
        duration: 4,
      });
      console.error(error);
    }
  };

  useEffect(() => {
    setValues({ ...report, patientId: patientId });
  }, [report]);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const hasError = hasErrorFactory(touched, errors);

  const handleTypeSelect = (value) => setFieldValue('type', value);

  return (
    <>
      <form onSubmit={handleSubmit}>

        <div className="form-group">
          <label htmlFor='title'>Titre</label>
          <Input
            type="text"
            id='title'
            name="title"
            placeholder="Titre"
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={values.title}
            className={hasError('title')}
          />
          {touched.title && errors.title && (
            <div className="error-message" style={{ color: 'red' }}>
              <br />
              {String(errors.title)}
            </div>
          )}
        </div>

        <div className='form-group'>
          <label htmlFor='type'>Type</label>
          <Select
            id='type'
            showSearch
            placeholder='Sélectionner le type'
            optionFilterProp='children'
            filterOption={selectOptionFilter}
            onChange={handleTypeSelect}
            onBlur={() => setFieldTouched('type')}
            className={hasError('type')}
            value={values.type}
          >
            <Select.Option value="Diagnostiques" style={{color: "blue"}}>Diagnostiques</Select.Option>
            <Select.Option value="Essai de prothése" style={{color: "red"}}>Essai de prothése</Select.Option>
            <Select.Option value="Contrôle d'appareillage" style={{color: "green"}}>Contrôle d'appareillage</Select.Option>
            <Select.Option value="Anamnèse" style={{color: "yellow"}}>Anamnèse</Select.Option>
            <Select.Option value="Test d'audition" style={{color: "black"}}>Test d'audition</Select.Option>
          </Select>
          {touched.type && errors.type && (
            <div className="error-message" style={{ color: 'red' }}>
              <br />
              {String(errors.type)}
            </div>
          )}
        </div>
        <div className="form-group">
  <label htmlFor='description'>Description</label>
  <Input
    type="text"
    id='description'
    name="description"
    placeholder="Description"
    onChange={handleChange}
    onBlur={handleBlur}
    defaultValue={values.description}
    className={hasError('description')}
  />
  {touched.description && errors.description && (
    <div className="error-message" style={{ color: 'red' }}>
      <br />
      {String(errors.description)}
    </div>
  )}
</div>
        <div className='d-flex justify-content-between buttons-list settings-actions'>
          <Button danger onClick={handleCancel} disabled={isSubmitting}>
            Annuler
          </Button>

          <Button
            type='primary'
            htmlType='submit'
            disabled={!isValid || isSubmitting}
          >
            {isSubmitting ? <Spin size="small" /> : submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ReportForm;
