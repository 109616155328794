import React, { useEffect, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './pdfViewer.scss';
import { useParams } from "react-router-dom";
import { IReport } from "../../../../../../../interfaces/patient";
import api from "../../../../../../../api";
import { IPageData } from "../../../../../../../interfaces/page";
import { usePageData } from "../../../../../../../hooks/usePage";
import { Button } from 'antd';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import type { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

type PdfViewerProps = {
    // url: string;
};

const ReportPage: React.FC<PdfViewerProps> = () => {
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
    });

    const { id } = useParams();
    const [report, setReport] = useState<IReport | null>(null);

    useEffect(() => {
        const getReport = async () => {
            await api.get(`report/${id}`).then(response => {
                setReport(response.data);
            });
        };
        getReport();
    }, [id]);

    const pageData: IPageData = {
        title: `Voir le rapport`,
        fulFilled: true,
        breadcrumbs: [
            {
                title: 'Medicine',
                route: '../admin/default-dashboard'
            },
            {
                title: 'Patients',
                route: '../admin/patients'
            },
            {
                title: `Voir le rapport`
            }
        ]
    };

    usePageData(pageData);

    const newPlugin = defaultLayoutPlugin();

    return (
        <>
            <div className="download-button">
                {report?.url && (
                    <a
                        className="no-decoration-link"
                        href={report.url}
                        style={{ textDecoration: "none" }}
                        download={`report-${id}.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button type="primary">
                            Télécharger le rapport
                        </Button>
                    </a>
                )}
            </div>
            <br />
            <div className="pdf-viewer">
                <br />
                <br />

                {report?.url ? (
                    <>
                        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11/build/pdf.worker.min.js`}>
                            <Viewer fileUrl={report.url} plugins={[toolbarPluginInstance]} />
                        </Worker>
                    </>
                ) : (
                    <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '18px', color: 'gray' }}>
                        Pas de fichier pour ce rapport
                    </div>
                )}
            </div>
        </>
    );
};

export default ReportPage;